.header {
    width: 100%;
    height: 70px;
    background-color: darken($primary, 3%);
    .back {
        width: 100%;
        height: 70px;
        position: absolute;
        top: 0;
        left: 0;
        backdrop-filter: blur(4px);
        // backdrop-filter: sepia(100%);
        // backdrop-filter: invert(80%);
        z-index: 101;
    }
    .data {
        width: 100%;
        height: 100%;
        position: relative;
        color: $white;
        z-index: 102;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .logo {
            white-space: nowrap;
            text-shadow: 1px 2px 0 $side-color, -1px 1px 0 $side-color-3;
        }
        .menu {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 30px;
            &>ul {
                display: flex;
                flex-direction: row;
                list-style: none;
                gap: 25px;
                font-weight: 900;
                &>li {
                    padding: 3px 12px;
                    transition: $transition;
                }
                &>li:nth-child(1) {
                    border-radius: 55px 40px 55px 35px;
                }
                &>li:nth-child(2) {
                    border-radius: 35px 55px 40px 55px;
                }
                &>li:nth-child(3) {
                    border-radius: 55px 35px 50px 45px;
                }
                &>li:nth-child(4) {
                    border-radius: 35px 50px 45px 55px;
                }
                &>li:hover {
                    cursor: pointer;
                    background-color: $side-color;
                    text-shadow: $text-shadow;
                }
            }
            
        }
    }
}
@import "./mixins.scss";

p, h1 {
    margin: 0;
    padding: 0;
    line-height: 1.6;
}

.body-main {
    width: 100%;
    background-color: $primary-back;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: $transition;
    gap: 150px;
    @include breakpoint(md) {
        gap: 100px;
    }
}

.loading {
    background-color: #3d3d1aa8;
    width: 100%;
    height: 100vh;
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

//! ------------------------------------------------------------------------------------ menu
.menu-section {
    position: fixed;
    top: 10px;
    right: 15px;
    min-height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    background-color: darken(#474747, 10%);
    color: $white;
    z-index: 1000;
    opacity: 0.7;
    &:hover {
        opacity: 1;
    }
    .menu-items {
        &>ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            list-style: none;
            gap: 5px;
            font-size: 1.2rem;
            padding: 15px 10px;
            margin: 0;
            transition: $transition;
            &>li {
                padding: 3px 10px;
                border-radius: 50px 40px 50px 40px;
            }
            &>li:hover {
                cursor: pointer;
                background-color: $side-color-6;
                text-shadow: $text-shadow;
            }
        }
        &.hide-menu {
            display: none;
        }
    }
    .menu-icon {
        // width: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 3px;
        padding: 3px 5px;
        transition: $transition;
        cursor: pointer;
        .menu-title {
            font-family: 'Courier New', Courier, monospace;
        }
        &.hide-icon {
            display: none;
        }
        @include breakpoint(sm) {
            .menu-title {
                display: none;
                svg {
                    font-size: 1.7rem;
                }
            }
        }
    }
}
.connect-wallet {
    position: fixed;
    top: 10px;
    left: 15px;
    width: 240px;
    z-index: 900;
    opacity: 0.7;
    &:hover {
        opacity: 1;
    }
    @include breakpoint(xs) {
        width: 200px;
    }
}

//! ------------------------------------------------------------------------------------ section-welcome
.section-welcome {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 10px 10px;
    .title {
        color: $side-color;
        font-size: 2rem !important;
        margin: 0;
        padding: 0;
        transition: $transition;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .icons {
        min-height: 330px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        position: relative;
        // flex-wrap: wrap;
        transition: $transition;
        gap: 15px;
        padding: 0 20px;
        overflow: hidden;
        @include breakpoint(sm) {
            height: 40%;
        }
        .inner {
            height: 220px;
            width: 220px;
            border-radius: 50%;
            overflow: hidden;
            transition: $transition;
            animation-name: welcome;
            animation-duration: $s1-animation-duration;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            transform: translate(0px, 15px);
            box-shadow: 0 5px 10px $side-color-9;
            &.order1, &.order2, &.order4, &.order5 {
                background-color: lighten($side-color-2, 10%);
                opacity: 0.8;
            }
            &.order3 {
                scale: 1.2;
                background-color: $side-color-7;
            }
            &:nth-child(1) {
                animation-delay: $s1-animation-duration/5;
                z-index: 5;
            }
            &:nth-child(2) {
                animation-delay: $s1-animation-duration/5*2;
                z-index: 4;
            }
            &:nth-child(3) {
                animation-delay: $s1-animation-duration/5*3;
                z-index: 3;
            }
            &:nth-child(4) {
                animation-delay: $s1-animation-duration/5*4;
                z-index: 2;
            }
            &:nth-child(5) {
                animation-delay: $s1-animation-duration/5*5;
                z-index: 1;
            }
            @keyframes welcome {
                0%   { transform: translate(0px, 15px); }
                100% { transform: translate(0px, -15px); }
            }
            @include breakpoint(xlBlg) {
                height: 180px;
                width: 180px;
                &.order1 {
                    scale: 1.1;
                }
            }
            @include breakpoint(lg) {
                height: 140px;
                width: 140px;
                &.order3 {
                    scale: 1;
                }
            }
            @include breakpoint(md) {
                height: 220px;
                width: 220px;
                &.order1, &.order2, &.order3, &.order4, &.order5 {
                    scale: 1;
                    opacity: 1;
                    background-color: $side-color-7;
                }
                position: absolute;
                animation-direction: normal;
                animation-duration: $s1-animation-duration-2;
                &:nth-child(1) { animation-delay: $s1-animation-duration-2/5; }
                &:nth-child(2) { animation-delay: $s1-animation-duration-2/5*2; }
                &:nth-child(3) { animation-delay: $s1-animation-duration-2/5*3; }
                &:nth-child(4) { animation-delay: $s1-animation-duration-2/5*4; }
                &:nth-child(5) { animation-delay: $s1-animation-duration-2/5*5; }
                @keyframes welcome {
                    0%   { opacity: 100%; }
                    10%  { opacity: 0; }
                    80%  { opacity: 0; }
                    100% { opacity: 100%; }
                }
            }
            &>img {
                pointer-events: none;
                height: 100%;
            }
        }
    }
    .text {
        color: $white;
        text-align: center;
        padding-top: 15px;
        p {
            font-size: 1.6rem;
            &:nth-child(2) {
                font-size: 1.7rem;
                color: $side-color-7;
            }
        }
        @include breakpoint(xs) {
            padding-top: 10px;
            p {
                font-size: 1.5rem;
            }
        }
    }
}
//! ------------------------------------------------------------------------------------ common
.section-title {
    color: $side-color;
    font-size: 4rem !important;
    margin-bottom: 30px;
    margin-top: 50px;
    padding: 0 !important;
    z-index: 100;
    text-shadow: $text-shadow;
    transition: $transition;
    &.lg-text{
        @include breakpoint(smBxs) {
            font-size: 2.5rem !important;
        }
    }
    &.lg-text2{
        @include breakpoint(lg) {
            font-size: 2.5rem !important;
        }
        @include breakpoint(sm) {
            font-size: 2rem !important;
        }
        @include breakpoint(xs) {
            font-size: 1.5rem !important;
        }
    }
    &.centered {
        text-align: center;
    }
    &.on-light {
        color: $side-color-3;
    }
}
.social {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 10px;
    a {
        border-radius: 50%;
        width: 38px;
        height: 38px;
        text-decoration: none;
        transition: $transition;
        &:hover {
            box-shadow: 0 0 5px $white;
            background-color: lighten($primary-back, 3%);
            &>svg {
                transition: $transition;
                color: $side-color-7;
            }
        }
    }
    //social-icon
    
}
//! ------------------------------------------------------------------------------------ section-mint
.section-mint {
    width: 100%;
    background: rgb(238,174,202);
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
    
    // background-color: $white;
    // background-image: url('./images/Clouds.png');
    // background-repeat: repeat;
    
    padding-bottom: 70px;
    min-height: 650px;
    position: relative;
    .top-side {
        width: 120%;
        height: 40px;
        rotate: 1deg;
        position: absolute;
        top: -15px;
        left: -5px;
        background-image: url('./images/wood-texture.png');
        background-repeat: repeat;
        background-size: 250px;
        box-shadow: 0 4px 15px rgb(45, 48, 46);
    }
    .bottom-side {
        width: 120%;
        height: 40px;
        rotate: -1deg;
        position: absolute;
        bottom: -15px;
        left: -5px;
        background-image: url('./images/wood-texture.png');
        background-repeat: repeat;
        background-size: 250px;
        box-shadow: 0 -4px 15px rgb(45, 48, 46);
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    .mint-body {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 0;
        gap: 40px;
        transition: $transition;
        @include breakpoint(lgBmd) {
            flex-direction: column;
        }
        .gen-show {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 20px;
            transition: $transition;
            @include breakpoint(sm) {
                gap: 10px;
            }
            .manage-icon {
                width: 38px;
                height: 38px;
                border-radius: 50%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                color: $primary-back;
                transition: $transition;
                // color: $white;
                // background-color: rgba(255, 255, 255, 0.5);
                cursor: pointer;
                &:hover {
                    box-shadow: $box-shadow;
                    background-color: rgba(255, 255, 255, 0.4);
                }
                @include breakpoint(sm) {
                    width: 30px;
                    height: 30px;
                    svg {
                        font-size: 1.3rem;
                    }
                }
                @include breakpoint(xs) {
                    width: 27px;
                    height: 27px;
                    svg {
                        font-size: 1.2rem;
                    }
                }
            }
            .image {
                width: 300px;
                height: 300px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                // box-shadow: 0 2px 10px rgba(255, 255, 255, 0.5);
                box-shadow: $box-shadow;
                overflow: hidden;
                transition: $transition;
                // background-color: rgba(255, 255, 255, 0.5);
                img {
                    width: 100%;
                    &.gray {
                        filter: grayscale(70%);
                    }
                }
                video {
                    width: 100%;
                }
                @include breakpoint(sm) {
                    width: 240px;
                    height: 240px;
                }
                @include breakpoint(xs) {
                    width: 220px;
                    height: 220px;
                }
            }
        }
        .gen-calculator {
            width: 320px;
            height: 210px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            border-radius: $border-radius-20;
            padding: 20px;
            box-shadow: $box-shadow-inner;
            @include breakpoint(sm) {
                width: 290px;
            }
            @include breakpoint(xs) {
                width: 250px;
                height: 190px;
            }
            .title {
                width: 100%;
                text-align: center;
                color: $white;
                font-size: 1.7rem;
                font-family: 'Courier New', Courier, monospace;
                padding: 5px;
                border-radius: $border-radius-15;
                margin: 0;
            }
            &.gray {
                background-color: rgb(184, 184, 184);
                .title {
                    background-color: rgb(156, 156, 156);
                    @include breakpoint(xs) {
                        font-size: 1.4rem;
                    }
                }
            }
            &.normal {
                background-color: lighten($side-color-2, 10%);
                .title {
                    background-color: lighten($side-color-2, 3%);
                    @include breakpoint(xs) {
                        font-size: 1.4rem;
                    }
                }
            }
            .comingSoon {
                text-align: center;
                color: $side-color-3;
                @include breakpoint(xs) {
                    font-size: 1.2rem;
                }
            }
            .funcs {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 20px;
                &>div {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    cursor: pointer;
                    box-shadow: 1px 1px 2px $side-color-3;
                    &:hover {
                        box-shadow: 0 0 1px $side-color-3;
                    }
                    &:nth-child(2) {
                        box-shadow: none;
                        cursor: default;
                    }
                    @include breakpoint(xs) {
                        width: 35px;
                        height: 35px;
                    }
                }
                &>div:first-child {
                    background-color: rgb(31, 199, 31);
                    &:hover {
                        background-color: rgb(28, 176, 28);
                    }
                }
                &>div:last-child {
                    background-color: rgb(206, 28, 54);
                    &:hover {
                        background-color: rgb(179, 26, 49);
                    }
                }
            }

        }
    }
}

//! ------------------------------------------------------------------------------------ section-airdrop
.section-airdrop {
    width: 100%;
    min-height: 500px;
    // background-color: #384b44;
    background: rgb(27, 27, 27);
    background: linear-gradient(0deg, rgba(27, 27, 27) 0%, rgba(27, 27, 27) 50%, rgba(27, 27, 27) 100%);
    padding: 20px;
    transition: $transition;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 50px;
    @include breakpoint(xs) {
        min-height: 600px;
    }
    .airdrop-items {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        color: $white;
        text-shadow: $text-shadow;
        transition: $transition;
        z-index: 100;
        background-color: rgba(255, 255, 255, 0.4);
        padding: 5px 12px;
        border-radius: $border-radius;
        @include breakpoint(sm) {
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: nowrap;
        }
        &>div{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 10px;
            svg {
                color: $side-color-3;
                font-size: 2.5rem;
                @include breakpoint(md) {
                    font-size: 2rem;
                }
            }
        }
    }
    .images {
        position: absolute;
        z-index: 1;
        img {
            width: 100%;
        }
        transition: $transition;
        animation-name: airdropImages;
        animation-duration: $s1-animation-duration;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        transform: translate(0px, 15px);
        &.back-image-0 {
            filter: blur(2.5px);
            width: 140px;
            top: 300px;
            right: 80px;
            rotate: 20deg;
            animation-delay: $s1-animation-duration/5;
        }
        &.back-image-1 {
            width: 300px;
            top: 20px;
            left: 20px;
            animation-delay: $s1-animation-duration/5*2;
        }
        &.back-image-2 {
            filter: blur(1.5px);
            width: 180px;
            top: -100px;
            right: 300px;
            animation-delay: $s1-animation-duration/5*3;
        }
        @keyframes airdropImages {
            0%   { transform: translate(0px, 15px); }
            100% { transform: translate(0px, -15px); }
        }
        // @include breakpoint(xl) {
        //     &.back-image-0 {
        //         right: 70px;
        //     }
        //     &.back-image-1 {
        //         width: 280px;
        //         top: 60px;
        //         left: 20px;
        //     }
        //     &.back-image-2 {
        //         right: 170px;
        //     }
        // }
        @include breakpoint(xlBlg) {
            &.back-image-0 {
                width: 130px;
            }
            &.back-image-1 {
                width: 280px;
            }
            &.back-image-2 {
                width: 160px;
            }
        }
        @include breakpoint(lgBmd) {
            &.back-image-0 {
                width: 100px;
                top: 350px;
            }
            &.back-image-1 {
                width: 220px;
                top: 100px;
                left: 10px;
            }
            &.back-image-2 {
                width: 140px;
                top: -50px;
                right: 150px;
            }
        }
        @include breakpoint(mdBsm) {
            &.back-image-0 {
                top: 420px;
                right: 20px;
            }
            &.back-image-1 {
                filter: blur(1px);
                width: 220px;
                left: -20px;
            }
            &.back-image-2 {
                filter: blur(1.5px);
                width: 180px;
                right: -10px;
            }
        }
        @include breakpoint(xs) {
            &.back-image-0 {
                width: 100px;
            }
            &.back-image-1 {
                width: 170px;
            }
            &.back-image-2 {
                width: 120px;
            }
        }
        
    }
}

//! ------------------------------------------------------------------------------------ section-generations
.section-generations {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px;
    .generations-items {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        gap: 40px;
        padding: 20px 0;
        @include breakpoint(xl) {
            gap: 30px;
        }
        @include breakpoint(lg) {
            gap: 20px;
        }
        .each {
            width: 200px;
            overflow: hidden;
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            transition: $transition;
            cursor: pointer;
            img {
                width: 100%;
                border-radius: $border-radius-20;
                // background-color: $side-color-7;
            }
            &.active {
                scale: 1.1;
                img {
                    background-color: $side-color-7;
                }
            }
            &.deactive {
                opacity: 0.5;
                img {
                    background-color: lighten($side-color-2, 10%);
                }
                &:hover {
                    scale: 103%;
                    opacity: 1;
                }
            }
            @include breakpoint(xl) {
                width: 165px;
            }
            @include breakpoint(lg) {
                width: 125px;
            }
            @include breakpoint(md) {
                width: 110px;
                p {
                    font-size: 1rem;
                }
            }
        }
    }
    .generations-abilities {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 5px 30px;
        transition: $transition;
        color: $white;
        background-color: rgba(227, 244, 227, 0.3);
        border-radius: $border-radius-20;
        padding: 20px;
        .abilities-inner {
            width: 100%;
            height: 25px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            transition: $transition;
            gap: 10px;
            &:last-child {
                margin: 0;
            }
            .title {
                width: 20%;
                text-align: right;
                font-family: 'Courier New', Courier, monospace;
                font-size: 1rem;
                font-weight: 900;
                transition: $transition;
                &::after {
                    content: ':';
                }
            }
            .perc {
                width: 80%;
                height: 100%;
                border: $border-light;
                border-radius: $border-radius;
                overflow: hidden;
                padding: 2px;
                transition: $transition;
                .perc-inner {
                    height: 100%;
                    background: rgb(242, 165, 42);
                    background: linear-gradient(90deg, rgb(242, 165, 42) 0%, rgb(239, 247, 10) 100%); 
                    transition: $transition;
                    border-radius: 7px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    padding: 0 10px;
                    .amount {
                        font-size: 1rem;
                        font-family: 'Courier New', Courier, monospace;
                        font-weight: 900;
                        color: $side-color-3;
                    }
                    @include breakpoint(sm) {
                        padding: 0 5px;
                        .amount {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        @include breakpoint(lg) {
            .abilities-inner {
                .title {
                    width: 30%;
                }
                .perc {
                    width: 70%;
                }
            }
           
        }
        @include breakpoint(md) {
            width: 100%;
            padding: 15px;
            .abilities-inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 45px;
                gap: 2px;
                .title {
                    white-space: nowrap;
                    text-align: left;
                    width: 100%;
                }
                .perc {
                    width: 100%;
                }
            }
        }
    }
}

//! ------------------------------------------------------------------------------------ section-roadmap
.section-roadmap {
    width: 95%;
    min-height: 70vh;
    border-radius: $border-radius-20;
    transition: $transition;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: $box-shadow;
    background-image: url('./images/net_1.png');
    background-repeat: repeat;
    .roadmap-items {
        width: 100%;
        height: 800px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: nowrap;
        overflow-x: scroll;
        gap: 20px;
        color: $side-color-3;
        transition: $transition;
        padding: 20px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        @include breakpoint(xlBlg) {
            justify-content: flex-start;
        }
        .inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            box-shadow: $box-shadow-inner;
            background-color: rgba(101, 101, 101, 0.65);
            border-radius: $border-radius;
            min-height: 600px;
            width: 480px;
            min-width: 250px;
            padding: 50px 5px 20px 5px;
            position: relative;
            overflow: hidden;
            .step-number {
                width: 100%;
                padding: 5px;
                background-color: $side-color-7;
                color: $white;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 0 0 0 0;
                p {
                    text-shadow: $text-shadow;
                }
            }
            img {
                width: 50%;
            }
            p {
                margin: auto 0;
                padding: 4px;
                color: white;
                text-align: center;
                // width: 100px;
            }
        }
    }
    .custom-scroll {
        width: 95%;
        height: 3px;
        border-radius: $border-radius-5;
        background-color: rgba(206, 206, 206, 0.3);
        overflow: hidden;
        display: none;
        .inner {
            width: 0;
            height: 100%;
            transition: $transition;
            background-color: $side-color-7;
        }
        @include breakpoint(xlBlg) {
            display: block;
        }
    }
}

//! ------------------------------------------------------------------------------------ section-information
.section-information {
    width: 90%;
    transition: $transition;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    @include breakpoint(md) {
        width: 100%;
    }
    .information-items-acco {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        z-index: 100;
        @include breakpoint(md) {
            width: 70%;
        }
        @include breakpoint(sm) {
            width: 90%;
        }
        .info-body {
            width: 100%;
            height: auto;
            border: $border;
            border-radius: $border-radius;
            border-color: #424242;
            overflow: hidden;
            .info-head {
                background-color: rgba(74, 74, 74, 0.7);
                padding: 15px 10px;
                color: $white;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                h3 {
                    margin: 0;
                    padding: 0;
                    text-shadow: 2px 3px 0 $side-color-9;
                }
                .icon {
                    transition: $transition-xlong;
                    display: block;
                    &.open {
                       rotate: 540deg;
                    }
                }
            }
            .info-content {
                padding: 0 10px;
                background-color: rgba(48, 107, 81, 0.7);
                overflow-y: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
                transition: $transition-xlong;
                height: 0;
                color: $white;
                p, strong {
                    text-shadow: 1px 2px 0 $side-color-9;
                }
                &.open {
                    height: 210px;
                    padding: 5px 10px;
                    @include breakpoint(xl) {
                        height: 240px;
                    }
                    @include breakpoint(lg) {
                        height: 280px;
                    }
                    @include breakpoint(md) {
                        height: 240px;
                    }
                    @include breakpoint(sm) {
                        height: 280px;
                    }
                }
            }

        }
    }
    .images {
        position: absolute;
        z-index: 1;
        img {
            width: 100%;
        }
        &.back-image-0 {
            width: 220px;
            top: 200px;
            right: -70px;
        }
        &.back-image-1 {
            width: 140px;
            top: 200px;
            left: -70px;
            transition: $transition;
            animation-name: bi1;
            animation-duration: 5s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
            animation-direction: alternate-reverse;
            @keyframes bi1 {
                0%   {
                    top: -50px;
                    transform: translate(0px, 0px);
                }
                47% {
                    transform: translate(0px, 0px);
                    opacity: 1;
                    rotate: 2deg;
                }
                48% {
                    opacity: 0;
                    rotate: 5deg;
                }
                50% {
                    top: -50px;
                    transform: translate(-300px, 0px);
                }
                53% {
                    transform: translate(-300px, 0px);
                    top: 200px;
                }
                55% {
                    opacity: 0;
                    rotate: -5deg;
                }
                56% {
                    rotate: -2deg;
                    opacity: 1;
                    transform: translate(0px, 0px);
                }
                100% {
                    transform: translate(0px, 0px);
                    top: 200px;
                }
            }
        }
        &.back-image-2 {
            width: 140px;
            top: 0px;
            right: -50px;
            transform: scaleX(-1);
            transition: $transition;
            animation-name: bi2;
            animation-duration: 5s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            animation-delay: 400ms;
            @keyframes bi2 {
                0%   {
                    top: 0px;
                    transform: translate(0px, 0px) scaleX(-1);
                }
                47% {
                    transform: translate(0px, 0px) scaleX(-1);
                    opacity: 1;
                    rotate: 2deg;
                }
                48% {
                    opacity: 0;
                    rotate: 5deg;
                }
                50% {
                    top: 0px;
                    transform: translate(300px, 0px) scaleX(-1);
                }
                53% {
                    transform: translate(300px, 0px) scaleX(-1);
                    top: 250px;
                }
                55% {
                    opacity: 0;
                    rotate: -5deg;
                }
                56% {
                    rotate: -2deg;
                    opacity: 1;
                    transform: translate(0px, 0px) scaleX(-1);
                }
                100% {
                    transform: translate(0px, 0px) scaleX(-1);
                    top: 250px;
                }
            }
        }
        @include breakpoint(md) {
            &.back-image-0 {
                width: 150px;
                top: 50px;
                right: -10px;
                filter: blur(1px);
                animation-name: none;
            }
            &.back-image-1 {
                width: 130px;
                top: 140px;
                left: 0px;
                filter: blur(0.7px);
                opacity: 0.8;
                animation-name: none;
            }
            &.back-image-2 {
                width: 130px;
                top: 70px;
                right: 10px;
                filter: blur(2px);
                opacity: 0.6;
                animation-name: none;
            }
        }
    }
}

//! ------------------------------------------------------------------------------------ section-comingSoon
.section-commingsoon {
    width: 95%;
    max-height: 70vh;
    border-radius: $border-radius-20;
    overflow: hidden;
    position: relative;
    filter: grayscale(60%);
    img {
        width: 100%;
        transition: $transition;
        object-position: 0 -100px;
        @include breakpoint(lg) {
            object-position: 0 -50px;
        }
        @include breakpoint(md) {
            object-position: 0 0;
            scale: 1.1;
        }
    }
    .on-body {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: darken($primary-back, 20%);
        opacity: 0.7;
    }
    .section-infos {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include breakpoint(sm) {
            padding-top: 20px;
            justify-content: flex-start;
        }
    }
}

//! ------------------------------------------------------------------------------------ section-team
.section-team {
    width: 100%;
    transition: $transition;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 20px 50px 20px;
    .team-items {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        color: $side-color-3;
        transition: $transition;
        border: 12px solid $side-color-3;
        background: rgb(255,251,231);
        background: linear-gradient(170deg, rgba(254, 251, 217, 0.7) 0%, rgba(255,255,255,0.3) 100%); 
        box-shadow: 3px 5px 7px #3a3a3a;
        border-radius: $border-radius-20;
        padding: 50px 40px;
        gap: 20px;
        @include breakpoint(md) {
            padding: 20px 10px;
        }
        .inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            color: $white;
            width: 225px;
            transition: $transition;
            gap: 10px;
            img {
                border-radius: $border-radius;
                width: 90%;
                box-shadow: 2px 5px 5px #6e6e6e;
                background: rgb(255,255,255);
                background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(74,115,117,0.5) 100%); 
                transition: $transition-long;
                &:hover {
                    box-shadow: 5px 15px 15px #7e7e7e;
                    transform: translate(0, -5px);
                }
            }
            p {
                margin: auto 0;
                padding: 0;
                text-align: center;
                font-weight: 900;
                text-shadow: 1px 1px 0 $side-color-9;
            }
            @include breakpoint(md) {
                width: 200px;
            }
            @include breakpoint(sm) {
                width: 150px;
                p {
                    font-size: 1.2rem;
                }
            }
        }

    }
}

//! ------------------------------------------------------------------------------------ section-footer
.section-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    padding-bottom: 70px;
    background: rgb(46, 46, 46);
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(66, 66, 66) 100%);
    .footer-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
        .section-1 {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            @include breakpoint(md) {
                height: 300px;
            }
            img {
                position: absolute;
                transition: $transition;
                top: 0;
                right: -10px;
                width: 250px;
                z-index: 1;
                @include breakpoint(md) {
                    width: 150px;
                }
                @include breakpoint(sm) {
                    top: -90px;
                }
            }
            p {
                z-index: 100;
                text-shadow: $text-shadow;
                text-align: center;
                padding: 0 20px;
            }
            .upper {
                color: $side-color;
                margin-bottom: 5px;
                font-size: 2rem;
                font-weight: 900;
            }
            .talk-to-us {
                width: 600px;
                z-index: 100;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;
                background-color: rgba(255, 255, 255, 0.3);
                transition: $transition-xlong;
                &:hover {
                    background-color: rgba(246, 239, 226, 0.5);
                }
                border-radius: $border-radius-20;
                padding: 12px;
                textarea {
                    caret-color: auto;
                    border: none;
                    outline: none;
                    border-radius: $border-radius-15 $border-radius-15 0 0;
                    padding: 15px;
                    width: 100%;
                    max-width: 100%;
                    min-width: 100%;
                    min-height: 100px;
                    max-height: 400px;
                    background-color: rgba(255, 255, 255, 0.8);
                    box-shadow: 0 4px 10px $side-color-9;
                    &:hover {
                        background-color: $white;
                    }
                }
                @include breakpoint(md) {
                    width: 75%;
                }
                @include breakpoint(sm) {
                    width: 90%;
                }
            }
        }
        .divider {
            height: 2px;
            background-color: rgba(98, 139, 114, 0.7);
            width: 85%;
        }
        .section-2 {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            padding: 30px;
            .footer-info {
                p:first-child {
                    font-weight: 900;
                    margin-bottom: 5px;
                    background-color: rgba(255, 255, 255, 0.2);
                    text-shadow: 1px 0.5px 0 $side-color-3;
                    border-radius: $border-radius;
                    padding: 3px 10px;
                }
                p:nth-child(2) {
                    padding-left: 20px;
                    border-left: 1px solid $side-color;
                }
                width: 90%;
            }
            @include breakpoint(md) {
                flex-direction: column;
                .footer-info {
                    width: 100%;
                    margin-bottom: 30px;
                }
            }
        }
    }
}
body {
    line-height: 108%;
    background-color: #2a6338;
    color: white;
    font-family: Calibri;
    font-size: 16pt;
}

p {
    margin: 16pt 16pt 8pt;
}

table {
    margin-top: 0pt;
    margin-bottom: 8pt;
}

.ListParagraph {
    margin-left: 36pt;
    margin-bottom: 8pt;
    line-height: 108%;
    font-size: 11pt;
}

@media (max-width: 900px) {
    img {
        max-width: 100%;
        height: auto;
    }

    .table-container {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    td,
    th {
        padding: 8px;
        text-align: left;
        border: 1px solid white;
    }
}

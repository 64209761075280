$white: #ffffff;
$primary-back: #1b1b1b;
$primary: #1b1b1b;
$primary2: #04400d;
$secondary: #032b09;
$first_ape: #3465a4;
$second_ape: #4c0b69;
$third_ape: #b1b4bd;
$fourth_ape: #bdd7ee;
$five_ape: #00F3FF;
$ability_one: #BC8C00;
$ability_two: #DAA400;
$ability_three: #F3B700;
$ability_four: #FFC859;
$ability_five: #FFD695;

$side-color: #f1f0e9;
$side-color-2: #8f7856;
$side-color-3: #3a3539;
$side-color-4: #a7bbda;
$side-color-5: #666664;
$side-color-6: #636363;
$side-color-7: #ffcc23;
$side-color-8: #0d3b56;
$side-color-9: #515151;

$gray47: #474747;

// borders
$border: 1px solid $side-color;
$border-light: 1px solid rgba(255, 255, 255, 0.3);

// shadow
$box-shadow: 1px 3px 10px $side-color-3;
$box-shadow-comingsoon: 0 0 20px #ff6200;
$box-shadow-inner: 0 1px 5px $side-color-3;
$text-shadow: 1px 2px 0 $gray47;

$border-radius-5: 5px;
$border-radius: 10px;
$border-radius-15: 15px;
$border-radius-20: 20px;

// animation-duration
$s1-animation-duration: 5000ms;
$s1-animation-duration-2: 10000ms;

// transition
$transition: all 200ms ease;
$transition-long: all 300ms ease;
$transition-xlong: all 400ms ease-in-out;
@import './font.scss';
* {
    box-sizing: border-box;
    scroll-behavior: smooth;
    caret-color: transparent;
}

body {
    margin: 0 !important;
    padding: 0 !important;
    font-family: Chalkboard !important;
    font-size: 1.4rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.font-noteworthy {
    font-family: Noteworthy !important;
    font-size: 2.2rem !important;
}

a {
    text-decoration: none !important;
    color: inherit !important;
}
.pushable {
    width: 100%;
    background-color: $side-color-2;
    border-radius: 55px 45px 55px 45px;
    border: none;
    padding: 0;
    .front {
        display: block;
        padding: 10px 20px;
        border-radius: 55px 45px 55px 45px;
        font-size: 1.25rem;
        font-family: inherit;
        background-color: $side-color;
        color: white;
        text-shadow: $text-shadow;
        font-weight: 500;
        transition: $transition;
        transform: translate(-3px, 4px);
        &:active {
            transform: translate(0px, 1px);
        }
        &:hover {
            cursor: pointer;
            background-color: lighten($side-color-2, 10%);
        }
        
    }
}

.main-btn {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: $border-radius-15;
    border: 2px solid lighten($side-color-3, 20%);
    transition: $transition;
    padding: 5px;
    min-height: 43px;
    color: white;
    z-index: inherit;
    .btn_title {
        font-size: 1.2rem;
        font-weight: 900;
    }
    &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.4);
        box-shadow: 0 0 10px $white;
    }
    &.green {
        background-color: #2b6f3c;
        border: none;
        color: white;
        border-radius: 0 0 $border-radius-15 $border-radius-15;
        box-shadow: 0 4px 10px $side-color-9;
        .btn_title {
            text-shadow: $text-shadow;
        }
        &:hover {
            background-color: #296738;
        }
    }
    &.black {
        background-color: #303030;
        border: none;
        color: white;
        border-radius: 0 0 $border-radius-15 $border-radius-15;
        box-shadow: 0 4px 10px $side-color-9;
        .btn_title {
            text-shadow: $text-shadow;
        }
        &:hover {
            background-color: #4a4a4a;
        }
    }
    &.yellow {
        background-color: rgba(243, 196, 68, 0.7);
        border: 2px solid rgb(205, 154, 16);
        color: white;
        .btn_title {
            text-shadow: $text-shadow;
        }
        &:hover {
            background-color: rgba(243, 196, 68, 0.8);
            box-shadow: 0 0 10px rgb(255, 228, 152);
        }
    }
    @include breakpoint(xs) {
        min-height: 38px;
        .btn_title {
            font-size: 1.1rem;
        }
    }
}